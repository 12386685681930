<template>
  <div class="mb-16">
    <h3 class="mb-3 text-victoria text-uppercase">UP Next Live</h3>
    <img
      class="w-75p  rounded-lg"
      src="/images/live-class-preview.jpg"
      alt=""
    />
    <h2 class="my-3 text-2xl line-height-27 font-bold text-uppercase">How to good at speaking</h2>
    <p class="text-green text-uppercase text-lg">Starting at 16:56</p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
