<template>
  <form @submit.prevent="onSubmit" class="p-6">
    <DialogTitle bg-color="bg-victoria" label="Create New Live Class" align="center" />
    <div class="md-layout md-gutter mt-6">
      <div class="md-layout-item md-size-70 mb-3">
        <InputFieldComponent
          itali
          label="Name"
          v-model.trim="$v.form.name.$model"
          :message="!$v.form.name.required && $v.form.name.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-80 mb-3">
        <InputFieldComponent
          itali
          label="Description"
          v-model.trim="$v.form.description.$model"
          :message="!$v.form.description.required && $v.form.description.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-35 mb-3">
        <InputFieldComponent
          itali
          label="Started Date"
          v-model.trim="$v.form.date.$model"
          :message="!$v.form.date.required && $v.form.date.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-45 mb-3">
        <InputFieldComponent
          itali
          label="Time"
          v-model.trim="$v.form.time.$model"
          :message="!$v.form.time.required && $v.form.time.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-35 mb-3">
        <SelectComponent
          label="Select Medium"
          italic
          class="w-full"
          v-model.trim="$v.form.medium.$model"
          :message="!$v.form.medium.required && $v.form.medium.$dirty ? 'Field is required' : null"
          />
      </div>
      <div class="md-layout-item md-size-45 mb-3">
        <InputFieldComponent
          itali
          label="Medium Link"
          v-model.trim="$v.form.mediumLink.$model"
          :message="!$v.form.mediumLink.required && $v.form.mediumLink.$dirty ? 'Field is required' : null"
        />
      </div>
    </div>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-35 mb-3">
        <SelectComponent
          label="Select Section"
          italic
          :placeholder="false"
          :items="sections"
          class="w-full"
          v-model.trim="$v.form.section.$model"
          :message="!$v.form.section.required && $v.form.section.$dirty ? 'Field is required' : null"
          />
      </div>
      <div class="md-layout-item md-size-45 mb-3">
        <SelectComponent
          label="Select Type"
          italic
          :items="questionTypes"
          class="w-full"
          v-model.trim="$v.form.type.$model"
          :message="!$v.form.type.required && $v.form.type.$dirty ? 'Field is required' : null"
          />
      </div>
      <div class="md-layout-item md-size-35 mb-3">
        <InputFieldComponent
          itali
          label="Duration"
          v-model.trim="$v.form.duration.$model"
          :message="!$v.form.duration.required && $v.form.duration.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="flex justify-end align-center md-layout-item md-size-100 mb-3">
         <md-button class="md-default">Save As Draft</md-button>
         <md-button class="border border-solid border-gray-500 rounded">Schedule</md-button>
         <md-button type="submit" class="bg-victoria text-white">Create</md-button>
      </div>
    </div>
  </form>
</template>

<script>
import { 
  SelectComponent,
  InputFieldComponent,
  DialogTitle
  } from "@/components";
import question from "@/data/question-creation/data";
import cms from "@/data/cms";
import { required } from "vuelidate/lib/validators";
import { mapMutations } from "vuex";
export default {
  components: {
    DialogTitle,
    SelectComponent,
    InputFieldComponent
  },
  data() {
    return {
      radio: true,
      sections: cms.sections,
      questionTypes: question.questionTypes,
      form: {
        name: "",
        description: "",
        date: "",
        time: "",
        medium: "",
        mediumLink: "",
        section: "",
        type: "",
        duration: ""
      }
    };
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
    onSubmit() {
      this.$v.$touch();
      if(this.$v.$invalid) {
        return;
      }
      this.setShowSnackbar('Live class saved successfully !');
      setTimeout(() => {
       this.dialog(false);
      }, 1000);
    }
  },
  validations: {
    form: {
      name: {required},
      description: {required},
      date: {required},
      time: {required},
      medium: {required},
      mediumLink: {required},
      section: {required},
      type: {required},
      duration: {required},
    }
  }
};
</script>

<style></style>
