<template>
  <div>
    <Card>
      <template slot="outer">
        <div class="mb-4 mt-6 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <i class="flaticon-video-camera"></i>
            <h3 class="pl-2 text-uppercase">Now Playing</h3>
          </div>
          <md-button class="text-white bg-victoria rounded" @click="onCreateNew">Create New</md-button>
        </div>
      </template>
      <CardBody class="p-3">
        <div class="md-layout md-gutter px-3 pt-3 pb-10">
          <div class="md-layout-item md-size-75">
            <div>
              <img
                class="w-full  rounded-lg"
                src="/images/live-class-preview.jpg"
                alt=""
              />
              <h2 class="text-3xl line-height-27 font-bold mt-6 ">How To Good at Listening</h2>
              <p class="text-victoria text-xl my-3 text-uppercase">
                MD. Hafizur Rahman
              </p>

              <div class="flex align-center">
                <md-button
                  class="bg-victoria m-0 w-40 text-white font-bold outline-gray-400 rounded"
                >
                  Watch Stream
                </md-button>
                <span class="ml-6 text-lg text-uppercase"
                  >Related materials
                </span>
                <a class="ml-2 text-victoria text-lg" href="https://apical.in"
                  >https://apical.in/material</a
                >
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-25">
            <NextLiveCard />
            <NextLiveCard />
          </div>
        </div>
      </CardBody>
    </Card>
    <Dialog>
      <LiveClassCreate />
    </Dialog>
  </div>
</template>

<script>
import {
  Card,
  Dialog,
  CardBody
} from "@/components";
import NextLiveCard from "@/components/molecule/live-class/NextLiveCard";
import LiveClassCreate from "@/components/molecule/live-class/LiveClassCreate";
import { mapMutations } from "vuex";

export default {
  name: "Todo-List",
  components: {
    Card,
    Dialog,
    CardBody,
    NextLiveCard,
    LiveClassCreate
  },
  data() {
    return {
      boolean: true,
    };
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onCreateNew() {
      this.dialog(true);
    }
  },
};
</script>
